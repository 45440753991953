import { useContext } from "react";

import parse from "html-react-parser";

import { siteOptionsContext } from "features/site-options";

import { NewsSubscription } from "features/news-subscription";

import { Link } from "components/common/Link";

import { ReactComponent as Vk } from "assets/vectors/vk-black.svg";
import { ReactComponent as Tg } from "assets/vectors/tg-outline.svg";
import { ReactComponent as YouTube } from "assets/vectors/youtube-outline.svg";
import { ReactComponent as WowLogo } from "assets/vectors/wow-logo.svg";

import paykeeper from "assets/images/paykeeper.png";
import partnerLogos from "assets/images/partner-logos.png";

import "./Footer.styles.scss";

function Footer() {
	const { siteOptions } = useContext(siteOptionsContext);

	const emailContent = siteOptions.find((option) => option.key === "email")
		?.value;
	const addressContent = siteOptions.find((option) => option.key === "address")
		?.value;
	const phoneContent = siteOptions.find((option) => option.key === "phone")
		?.value;
	const vkLink = siteOptions.find((option) => option.key === "vk_link")?.value;
	const tgLink = siteOptions.find((option) => option.key === "telegram_link")?.value;
	const youtubeLink = siteOptions.find((option) => option.key === "youtube_link")?.value;
	const safetyLink = siteOptions.find((option) => option.key === "safety")
		?.value;

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer__wrap">
					<div className="footer-wrap">
						<div className="footer-wrap__row">
							<div className="footer-wrap__col">
								<div className="footer-info">
									<ul className="footer-info__list">
										<h3 className="footer-info__title">О компании</h3>
										<li className="footer-info__item">
											<Link
												nav={false}
												to="/blog"
												classes="footer-info__link"
												autoScrollable={true}
											>
												Блог
											</Link>
										</li>
										<li className="footer-info__item">
											<Link
												nav={false}
												to="/delivery"
												classes="footer-info__link"
												autoScrollable={true}
											>
												Доставка
											</Link>
										</li>
										{/* <li className="footer-info__item">
											<a href="/" className="footer-info__link">
												Сайт для бизнеса
											</a>
										</li> */}
									</ul>
								</div>
							</div>

							<div className="footer-wrap__col">
								<div className="footer-info">
									<ul className="footer-info__list">
										<h3 className="footer-info__title">Контакты</h3>
										<li className="footer-info__item footer-info__item--address">{parse(addressContent ?? "")}</li>
										<li className="footer-info__item">
											<a
												href={"mailto:" + emailContent}
												className="footer-info__link"
											>
												{emailContent}
											</a>
										</li>
										<li className="footer-info__item">
											<a
												href={"tel:" + phoneContent}
												className="footer-info__link"
											>
												{phoneContent}
											</a>
										</li>
									</ul>
								</div>
							</div>

							<div className="footer-wrap__col">
								<div className="footer-info">
									<div className="footer-info__list">
										<h3 className="footer-info__title">Соцсети</h3>
										<div className="footer-info__socs">
											<a
												href={vkLink}
												target="_blank"
												rel="noreferrer"
												className="footer-info__soc"
											>
												<Vk />
											</a>
											<a
												href={tgLink}
												target="_blank"
												rel="noreferrer"
												className="footer-info__soc"
											>
												<Tg />
											</a>
											<a
												href={youtubeLink}
												target="_blank"
												rel="noreferrer"
												className="footer-info__soc"
											>
												<YouTube />
											</a>
										</div>

										<span className="footer-info__item footer-info__created">
											Created by
											<a
												href="https://wow.wearewowagency.com/"
												target="_blank"
												rel="noreferrer"
											>
												<WowLogo />
											</a>
										</span>
									</div>
								</div>
							</div>


							<div className="footer-wrap__col">
								<div className="footer-info">
									<div className="footer-info__list">
										<div className="paykeeper-head footer-info__title">
											<img src={paykeeper} alt="" />
										</div>
										<div className="paykeeper-logos">
											<img src={partnerLogos} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="footer-wrap__row footer-wrap__row--second">
							<div className="footer-wrap__col">
								<div className="footer-info">
									<ul className="footer-info__list">
										<li className="footer-info__item">
											<a href={safetyLink} className="footer-info__link">
												Политика конфиденциальности
											</a>
										</li>
										<li className="footer-info__item">
											2023 © KOF. — компания по обжарке кофе
										</li>
									</ul>
								</div>
							</div>

							<div className="footer-wrap__col footer-wrap__col--fulldesktop">
								<div className="footer-info">
									<h2 className="footer-info__subscribe">
										Подписаться на рассылку
									</h2>
								</div>
							</div>

							<div className="footer-wrap__col footer-wrap__col--empty"></div>

							<div className="footer-wrap__col footer-wrap__col--fulldesktop">
								<div className="footer-info">
									<NewsSubscription />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export { Footer };
